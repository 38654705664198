<template>
  <div class="statisticalDetails flex-column-between">
    <div class="Mian flex-column-between">
      <div class="statisticalItems flex-column-between">
        <div class="nav">活动统计 / 活动名称</div>
        <div class="Allkinds flex-align-between">
          <div class="kindItem flex-align-center" v-for="(item, index) in kindItemList" :key="index">
            <div class="kindItem_box flex-align-between">
              <img :src="item.icon" />
              <div class="kindItemData flex-column-between">
                <div class="kindItemnumber" :style="{ color: item.color }">
                  <div>{{ item.nums }}</div>
                  <!-- <div v-if="index == 0">%</div> -->
                </div>
                <div class="kindItemNanme">{{ item.categoryName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="explain">
          *更新说明：非实时更新，作品提交后24小时更新统计数据
        </div>
      </div>
      <div class="StatisticsList">
        <div class="data_screen">
          <div class="top">
            <div class="project_sch">
              <el-select v-model="projectValue" placeholder="请选择" :popper-append-to-body="false" @change="changeSchool">
                <el-option v-for="item in projectOptions" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="searchbox flex-align-between">
              <el-input v-model="inputValue" placeholder="输入姓名/手机号搜索"></el-input>
              <div class="searchBtn flex-align-center" @click="search">搜索</div>
            </div>
            <div class="download flex-align-between" @click="download">
              <img src="../../assets/surfaceHome/xiazai.png" />
              <span>下载表格</span>
            </div>
          </div>
          <div class="screenMain">
            <!-- <table boder="0" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>手机号</th>
                  <th>项目校</th>
                  <th>作品数</th>
                  <th>总字数</th>
                  <th>优秀</th>
                  <th>良好</th>
                  <th>待提升</th>
                  <th>点赞数</th>
                  <th>发帖数</th>
                  <th>回复数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tabData" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.phone}}</td>
                  <td>{{item.schoolName}}</td>
                  <td class="color1">{{item.workCount}}</td>
                  <td>{{item.wordsCount}}</td>
                  <td class="color2">天，波，日</td>
                  <td class="color3">落，去</td>
                  <td class="color4">飞</td>
                  <td>{{item.likesCount}}</td>
                  <td>{{item.postCount}}</td>
                  <td>{{item.replyCount}}</td>
                </tr>
              </tbody>
            </table> -->
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="name" label="姓名" width="120" align="center" show-overflow-tooltip fixed>
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center" width="110" fixed>
              </el-table-column>
              <el-table-column prop="schoolName" label="项目校" align="center" width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="workCount" label="作品数" align="center">
              </el-table-column>
              <el-table-column prop="wordsCount" label="总字数" align="center">
              </el-table-column>
              <el-table-column prop="excellentWords" label="优秀" align="center" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                  <span v-for="item,index in scope.row.excellentWords" :key="index">{{item.charName}}<i
                      v-if="index!=scope.row.excellentWords.length-1">，</i></span>
                </template>
              </el-table-column>
              <el-table-column prop="goodWords" label="良好" align="center" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                  <span v-for="item,index in scope.row.goodWords" :key="index">{{item.charName}}<i
                      v-if="index!=scope.row.goodWords.length-1">，</i></span>
                </template>
              </el-table-column>
              <el-table-column prop="badWords" label="待提升" align="center" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                  <span v-for="item,index in scope.row.badWords" :key="index">{{item.charName}}<i
                      v-if="index!=scope.row.badWords.length-1">，</i></span>
                </template>
              </el-table-column>
              <el-table-column prop="likesCount" label="点赞数" align="center">
              </el-table-column>
              <el-table-column prop="postCount" label="发帖数" align="center">
              </el-table-column>
              <el-table-column prop="replyCount" label="回复数" align="center">
              </el-table-column>
            </el-table>

          </div>
        </div>
        <div class="Tablepaging flex-align-center">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage4" :page-size="limit" :page-sizes="[3, 5, 8]"
            layout="total,sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="Footer"></div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "",
    components: {},
    data() {
      return {
        kindItemList: [{
            icon: require("../../assets/surfaceHome/Numberofparticipants.png"),
            nums: "10",
            categoryName: "参与人数",
            color: "#00C4C2",
          },
          {
            icon: require("../../assets/surfaceHome/Numberofworks2.png"),
            nums: "10",
            categoryName: "作品数",
            color: "#FE6C53",
          },
          {
            icon: require("../../assets/surfaceHome/fabulous.png"),
            nums: "10",
            categoryName: "点赞",
            color: "#B198DC",
          },
          {
            icon: require("../../assets/surfaceHome/Posting.png"),
            nums: "10",
            categoryName: "发帖数",
            color: "#3DD76C",
          },
          {
            icon: require("../../assets/surfaceHome/reply2.png"),
            nums: "10",
            categoryName: "回复数",
            color: "#41C9FF",
          },
        ],
        projectValue: "项目学校",
        projectOptions: [],
        inputValue: "",
        currentPage4: 1,
        page: 1,
        limit: 5,
        total: 0,
        tableData: [],
        schoolId: '',
      };
    },
    methods: {
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val;
        this.getChildActiveData()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.limit = val;
        this.getChildActiveData()
      },
      // 子活动统计数据
      async getChildActiveData() {
        let data = {
          page: this.page,
          limit: this.limit,
          shareCode: this.$route.query.shareCode,
          schoolId: this.schoolId,
          phone: this.inputValue
        }
        let resData = await this.$Api.Manage.getChildStatistics(data)
        console.log('子活动统计数据', resData);
        this.$set(this.kindItemList, 0, {
          ...this.kindItemList[0],
          nums: resData.data.activityMemberCount
        })
        this.$set(this.kindItemList, 1, {
          ...this.kindItemList[1],
          nums: resData.data.workCount
        })
        this.$set(this.kindItemList, 2, {
          ...this.kindItemList[2],
          nums: resData.data.likesCount
        })
        this.$set(this.kindItemList, 3, {
          ...this.kindItemList[3],
          nums: resData.data.postCount
        })
        this.$set(this.kindItemList, 4, {
          ...this.kindItemList[4],
          nums: resData.data.replyCount
        })
        try {
          resData.data.schoolVos.unshift({
            id: '',
            name: '全部学校'
          })
        } catch (err) {
          console.log(err)
        }

        this.projectOptions = resData.data.schoolVos
        this.tableData = resData.data.list
        this.total = resData.data.total
        for (let i = 0; i < this.tableData.length; i++) {
          let wordsList = this.tableData[i].words;
          let excellentList = []
          let goodList = []
          let badList = []
          for (let k = 0; k < wordsList.length; k++) {
            if (wordsList[k].score >= 80) excellentList.push(wordsList[k])
            if (wordsList[k].score < 80 && wordsList[k].score >= 65) goodList.push(wordsList[k])
            if (wordsList[k].score < 65) badList.push(wordsList[k])
          }
          this.$set(this.tableData[i], 'excellentWords', excellentList);
          this.$set(this.tableData[i], 'goodWords', goodList);
          this.$set(this.tableData[i], 'badWords', badList);
        }
      },
      // 项目学校筛选
      changeSchool(val) {
        console.log(val)
        this.schoolId = val;
        this.getChildActiveData()
      },
      // 搜索
      search() {
        this.getChildActiveData()
      },
      // 导出数据
      download() {
        console.log(
          `${axios.defaults.baseURL}/learn/word/test/getSubActivityUserDataEx/export?shareCode=${this.$route.query.shareCode}`
          )
        window.location.href =
          `${axios.defaults.baseURL}/learn/word/test/getSubActivityUserDataEx/export?shareCode=${this.$route.query.shareCode}`;
      }
    },
    mounted() {
      this.getChildActiveData()
    },
  };
</script>
<style lang="less" scoped>
  @import "./less/statisticalDetails.less";
</style>
<style>
  .Tablepaging .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #21bd99 !important;
    color: #fff !important;
  }

  .Tablepaging .el-pagination.is-background .el-pager li:hover {
    color: #21bd99 !important;
  }

  .Tablepaging .el-pager li.active {
    color: #21bd99 !important;
  }

  .Tablepaging .el-input__inner:focus {
    border-color: #21bd99;
  }

  .project_sch .el-select .el-input .el-input__inner {
    width: 150px !important;
    height: 35px;
    min-height: 35px;
  }

  .project_sch .el-select .el-input .el-input__suffix {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchbox .el-input__inner {
    width: 200px !important;
    height: 35px;
  }
  
</style>